import Vue from 'vue'
import {
  Button,
  Icon,
  Affix,
  Layout,
  Menu,
  Form,
  Input,
  Select,
  Checkbox,
  Radio,
  Row,
  Col,
  Table,
  Popconfirm,
  Modal,
  Upload,
  Card,
  Tabs,
  message,
  Avatar,
  Dropdown,
  Divider,
  List,
  Alert,
  Popover,
  Spin,
  Steps,
  DatePicker,
  Switch,
  InputNumber,
  LocaleProvider,
  Badge,
  Tag,
  Timeline,
  Statistic,
  Cascader,
  ConfigProvider,
  Pagination,
  Breadcrumb,
  Space,
  Empty,
  Tooltip,
  Progress
} from 'ant-design-vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import echarts from 'echarts'
import './assets/style/custom-theme.less'
import './assets/style/app.less'
import store from './store'
import router from './router'
import App from './App.vue'
Vue.use(VueRouter)
Vue.use(VueResource)
// Vue.use(Antd)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Affix)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(Form)
Vue.use(Input)
Vue.use(Select)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(Popconfirm)
Vue.use(Modal)
Vue.use(Upload)
Vue.use(Card)
Vue.use(Tabs)
Vue.use(Avatar)
Vue.use(Dropdown)
Vue.use(Divider)
Vue.use(List)
Vue.use(Alert)
Vue.use(Spin)
Vue.use(Steps)
Vue.use(DatePicker)
Vue.use(Popover)
Vue.use(Switch)
Vue.use(InputNumber)
Vue.use(LocaleProvider)
Vue.use(Badge)
Vue.use(Tag)
Vue.use(Timeline)
Vue.use(Statistic)
Vue.use(Cascader)
Vue.use(ConfigProvider)
Vue.use(Pagination)
Vue.use(Progress)
Vue.use(Breadcrumb)
Vue.use(Space)
Vue.use(Empty)
Vue.use(Tooltip)
Vue.prototype.$message = message

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

// Vue.http.headers.common.Authorization = `Bearer ${cookie.parse(document.cookie).jwt_token}`
Vue.http.interceptors.push((request, next) => {
  next(response => {
    // console.log('response:', response)
    if (!response.ok && response.status === 401) {
      if (store.state.user && store.state.user.currentUser && store.state.user.currentUser.isOrgAdmin) {
        window.location.href = `/org/${store.state.school.currentOrg.code}`
      } else {
        window.location.href = `/police/${store.state.school.currentSchool.code}`
      }
    } else if (response.body.err) {
      // console.warn(response.body.errMsg)
      message.error(response.body.errMsg)
    }
    return response
  })
})

new Vue({
  provide: () => ({
    eventBus: new Vue()
  }),
  render: (h) => h(App),
  router,
  store
}).$mount('#app')

/*
 **k12改警局，文案调整
  机构（Organization）    →  总局
  学校（School）          →  分局
  学校类别（SchoolType）  →  分局类别
  学生（User）            →  警员
  年级（grade）           →  部门
  班级（StudentGroup）    →  子部门
  班主任                  →  负责人
  学号                    →  编号
  机构管理员（OrgAdmin）   →  总局管理员
  学校管理员（SchoolAdmin）→  分局管理员
*/


// initial state
const state = {
  currentClass: {}
}

// getters
const getters = {}

// actions
const actions = {
  saveCurrClass ({ commit }, data) {
    commit('saveCurrClass', data)
  },
  nutritionStorage ({ commit }) {
    commit('nutritionStorage')
  },
  nutritionRestore ({ commit }) {
    const item = sessionStorage.getItem('currentClass')
    if (item) {
      commit('saveCurrClass', JSON.parse(item))
      sessionStorage.removeItem('currentClass')
    }
  }
}

// mutations
const mutations = {
  saveCurrClass (state, data) {
    state.currentClass = data || {}
  },
  nutritionStorage (state) {
    sessionStorage.setItem('currentClass', JSON.stringify(state.currentClass))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import backendApis from './backendApis'

export default {
  orgAdmin: {
    login: '/api/v1/org-admin/login',
    logout: '/api/v1/org-admin/logout',
    schoolList: '/api/v1/org-admin/school-list',
    modifyPwd: '/api/v1/org-admin/change-password',
    create: '/api/v1/org-admin/save',
    update: '/api/v1/org-admin/save/',
    delete: '/api/v1/org-admin/del/',
    list: '/api/v1/org-admin',
    isExisted: '/api/v1/org-admin/is-existed'
  },
  schoolAdmin: {
    login: '/api/v1/school-admin/login',
    logout: '/api/v1/school-admin/logout',
    modifyPwd: '/api/v1/school-admin/change-password',
    create: '/api/v1/school-admin/save',
    update: '/api/v1/school-admin/save/',
    delete: '/api/v1/school-admin/del/',
    list: '/api/v1/school-admin',
    isExisted: '/api/v1/school-admin/is-existed'
  },
  user: {
    list: '/api/v1/user',
    detail: '/api/v1/user/detail/',
    create: '/api/v1/user/save',
    modify: '/api/v1/user/save/',
    delete: '/api/v1/user/del/',
    deleteMany: '/api/v1/user/delete-many',
    importExcel: '/api/v1/user/import',
    exportExcel: '/api/v1/user/export',
    listOfSchool: '/api/v1/user/list-by-schoolid',
    statistics: '/api/v1/user/statistics',
    lastEditTime: '/api/v1/user/record-summary/last-edit-time',
    changeEnableState: '/api/v1/user/change-enable-state',
    count: '/api/v1/user/count',
    isExisted: '/api/v1/user/is-number-existed',
    mealCardInfo: '/api/v1/user/meal-card-info'
  },
  school: {
    listByOrgId: '/api/v1/school/list-by-orgid',
    schoolName: '/api/v1/school/name-by-code/',
    schoolCode: '/api/v1/school/code-by-name',
    nameById: '/api/v1/school/name-by-id/'
  },
  organization: {
    orgName: '/api/v1/organization/name-by-code/',
    orgCode: '/api/v1/organization/code-by-name'
  },
  schoolType: {
    list: '/api/v1/school-type'
  },
  studentGroup: {
    create: '/api/v1/student-group/save',
    update: '/api/v1/student-group/save/',
    delete: '/api/v1/student-group/del/',
    list: '/api/v1/student-group',
    count: '/api/v1/student-group/',
    saveMany: '/api/v1/student-group/save-many',
    isExisted: '/api/v1/student-group/is-existed'
  },
  nutrition: {
    classesNutrition: '/api/v1/nutrition/classes',
    studentsNutrition: '/api/v1/nutrition/class-students',
    studentsNutritionExport: '/api/v1/nutrition/students-nutrition-export',
    statistics: '/api/v1/nutrition/statistics',
    relatedPersonnel: '/api/v1/nutrition/related-personnel',
    relatedCanteen: '/api/v1/nutrition/related-canteen',
    mealData: '/api/v1/nutrition/user-meal-data',
    oneClassStatistics: '/api/v1/nutrition/one-class-statistics',
    exportRelatedCanteen: '/api/v1/nutrition/export-related-personnel'
  },
  supply: {
    list: '/api/v1/supply/list',
    exportExcel: '/api/v1/supply/export',
    importExcel: '/api/v1/supply/import'
  },
  wechat: {
    sms: '/api/v1/wechat/register-sms',
    regist: '/api/v1/wechat/register',
    groupList: '/api/v1/wechat/group-list'
  },
  backend: backendApis
}

import Vue from 'vue'
import apis from '@/business/apis'
import { all } from '@/business/enums'

// initial state
const state = {
  currentOrg: { _id: '' },
  currentSchool: { name: '', _id: '' },
  schoolTypeList: []
}

// getters
const getters = {
  official_display: (state, getters) => {
    return state.currentOrg.official_display || state.currentSchool.official_display
  },
  schoolGrades: (state, getters) => {
    if (!state.currentSchool.type) {
      return [all]
    }
    const schoolType = state.schoolTypeList.filter(item => item.name === state.currentSchool.type[0])
    const list = schoolType[0] && schoolType[0].grades ? [all, ...schoolType[0].grades] : [all]
    return list
  }
}

// actions
const actions = {
  setCurrOrg ({ commit }, organization) {
    commit('setCurrOrg', organization)
  },
  setCurrSchool ({ commit }, school) {
    commit('setCurrSchool', school)
  },
  async loadSchoolTypeList ({ commit }) {
    const res = await Vue.http.get(apis.schoolType.list)
    const list = res.body && res.body.list ? res.body.list : []
    commit('setSchoolTypeList', list)
  },
  schoolStorage ({ commit }) {
    commit('schoolStorage')
  },
  schoolRestore ({ commit }) {
    const org = sessionStorage.getItem('currentOrg')
    if (org) {
      commit('setCurrOrg', JSON.parse(org))
      sessionStorage.removeItem('currentOrg')
    }
    const school = sessionStorage.getItem('currentSchool')
    if (school) {
      commit('setCurrSchool', JSON.parse(school))
      sessionStorage.removeItem('currentSchool')
    }
    // const schoolTypes = sessionStorage.getItem('schoolTypeList')
    // if (schoolTypes) {
    //   commit('setSchoolTypeList', JSON.parse(schoolTypes))
    //   sessionStorage.removeItem('schoolTypeList')
    // }
  }
}

// mutations
const mutations = {
  setCurrOrg (state, organization) {
    state.currentOrg = organization || {}
  },
  setCurrSchool (state, school) {
    state.currentSchool = school || {}
  },
  setSchoolTypeList (state, list) {
    state.schoolTypeList = list
  },
  schoolStorage (state) {
    sessionStorage.setItem('currentOrg', JSON.stringify(state.currentOrg))
    sessionStorage.setItem('currentSchool', JSON.stringify(state.currentSchool))
    // sessionStorage.setItem('schoolTypeList', JSON.stringify(state.schoolTypeList))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

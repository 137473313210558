import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    name: 'OrgAdminLogin',
    path: '/org/:orgCode',
    component: () => import('@/views/Login.vue')
  },
  {
    name: 'SchoolAdminLogin',
    path: '/police/:schoolCode',
    component: () => import('@/views/Login.vue')
  },
  {
    name: 'SelectSchool',
    path: '/select-police',
    component: () => import('@/views/SelectSchool.vue')
  },
  {
    name: 'ClassConfig',
    path: '/group-config',
    component: () => import('@/views/ClassConfig.vue')
  },
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        name: 'FileManage',
        path: 'file-manage',
        component: () => import('@/views/fileManage/Index.vue')
      },
      {
        name: 'NutritionMonitorIndex',
        path: 'nutrition-monitor',
        component: () => import('@/views/nutritionMonitor/Index.vue')
      },
      {
        name: 'ClassNutritionDetail',
        path: 'group-detail',
        component: () => import('@/views/nutritionMonitor/ClassDetail.vue')
      },
      {
        name: 'NutritionReport',
        path: 'nutrition-report/:userId',
        component: () => import('@/views/nutritionMonitor/NutritionReport.vue')
      },
      {
        name: 'FoodSafetyDetect',
        path: 'food-safety-detect',
        component: () => import('@/views/foodSafetyDetect/Index.vue')
      },
      {
        name: 'FoodSafetyTraced',
        path: 'food-safety-traced',
        component: () => import('@/views/foodSafetyTraced/Index.vue')
      },
      {
        name: 'MealCardManage',
        path: 'meal-card-manage',
        component: () => import('@/views/mealCardManage/Index.vue')
      },
      {
        name: 'SchoolUserManage',
        path: 'police-user-manage',
        component: () => import('@/views/systemManage/SchoolUserManage.vue')
      },
      {
        name: 'OrgUserManage',
        path: 'org-user-manage',
        component: () => import('@/views/systemManage/OrgUserManage.vue')
      },
      {
        name: 'ClassManage',
        path: 'group-manage',
        component: () => import('@/views/systemManage/ClassManage.vue')
      },
      {
        name: 'ModifyPassword',
        path: 'modify-password',
        component: () => import('@/views/systemManage/ModifyPassword.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import { mapActions } from 'vuex'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')
export default {
  name: 'app',
  data () {
    return {
      zh_CN: zhCN
    }
  },
  methods: {
    ...mapActions('user', [
      'userStorage',
      'userRestore'
    ]),
    ...mapActions('school', [
      'schoolStorage',
      'schoolRestore'
    ]),
    ...mapActions('nutrition', [
      'nutritionStorage',
      'nutritionRestore'
    ]),
    async beforeUnloadFn () {
      await this.userStorage()
      await this.schoolStorage()
      await this.nutritionStorage()
    }
  },
  async created () {
    await this.userRestore()
    await this.schoolRestore()
    await this.nutritionRestore()
    window.addEventListener('beforeunload', e => this.beforeUnloadFn(e))
  },
  async beforeDestroy () {
    window.removeEventListener('beforeunload', e => this.beforeUnloadFn(e))
  }
}
</script>

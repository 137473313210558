export default {
  sysAdmin: {
    login: '/backend/api/v1/sys-admin/login',
    logout: '/backend/api/v1/sys-admin/logout',
    list: '/backend/api/v1/sys-admin',
    detail: '/backend/api/v1/sys-admin/detail',
    save: '/backend/api/v1/sys-admin/save',
    delete: '/backend/api/v1/sys-admin/del/'
  },
  canteen: {
    list: '/backend/api/v1/canteen',
    detail: '/backend/api/v1/canteen/',
    save: '/backend/api/v1/canteen/save',
    delete: '/backend/api/v1/canteen/del/'
  },
  organization: {
    list: '/backend/api/v1/organization',
    detail: '/backend/api/v1/organization/',
    save: '/backend/api/v1/organization/save',
    delete: '/backend/api/v1/organization/del/',
    check: '/backend/api/v1/organization/check/'
  },
  school: {
    list: '/backend/api/v1/school',
    detail: '/backend/api/v1/school/',
    save: '/backend/api/v1/school/save',
    delete: '/backend/api/v1/school/del/',
    group: '/backend/api/v1/school/group/'
  },
  user: {
    list: '/backend/api/v1/user'
    // detail: '/backend/api/v1/user/',
    // save: '/backend/api/v1/user/save',
    // delete: '/backend/api/v1/user/del/'
  },
  orgAdmin: {
    list: '/backend/api/v1/org-admin',
    detail: '/backend/api/v1/org-admin/',
    save: '/backend/api/v1/org-admin/save',
    delete: '/backend/api/v1/org-admin/del/'
  },
  schoolAdmin: {
    list: '/backend/api/v1/school-admin',
    detail: '/backend/api/v1/school-admin/',
    save: '/backend/api/v1/school-admin/save',
    delete: '/backend/api/v1/school-admin/del/'
  },
  schoolType: {
    list: '/backend/api/v1/school-type',
    detail: '/backend/api/v1/school-type/',
    save: '/backend/api/v1/school-type/save',
    delete: '/backend/api/v1/school-type/del/'
  },
  studentGroup: {
    list: '/backend/api/v1/student-group',
    detail: '/backend/api/v1/student-group/',
    save: '/backend/api/v1/student-group/save',
    delete: '/backend/api/v1/student-group/del/'
  },
  department: {
    list: '/backend/api/v1/department',
    detail: '/backend/api/v1/department/',
    save: '/backend/api/v1/department/save',
    delete: '/backend/api/v1/department/del/'
  },
  mealRecord: {
    list: '/backend/api/v1/meal-record'
    // detail: '/backend/api/v1/meal-record/',
    // save: '/backend/api/v1/meal-record/save',
    // delete: '/backend/api/v1/meal-record/del/'
  },
  stockAction: {
    list: '/backend/api/v1/stock-action'
    // detail: '/backend/api/v1/stock-action/',
    // save: '/backend/api/v1/stock-action/save',
    // delete: '/backend/api/v1/stock-action/del/'
  },
  userRecordSummary: {
    list: '/backend/api/v1/user-record-summary'
  },
  canteenConfig: {
    list: '/backend/api/v1/canteen-config',
    detail: '/backend/api/v1/canteen-config/',
    save: '/backend/api/v1/canteen-config/save',
    delete: '/backend/api/v1/canteen-config/del/'
  }
}

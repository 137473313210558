
// initial state
const state = {
  currentUser: { name: '黄经理', isOrgAdmin: false, roles: [], avatar: 'http://www.botwave.com/tmp/jshavatar/default-avatar.jpg' }
}

// getters
const getters = {
  isTeacher: (state, getters) => {
    if (state.currentUser.isOrgAdmin) {
      return false
    }
    const role = state.currentUser.roles.filter(item => item === 'admin')
    if (!role || !role.length) return true
    return false
  }
}

// actions
const actions = {
  saveCurrUser ({ commit }, user) {
    commit('saveCurrUser', user)
  },
  userStorage ({ commit }) {
    commit('userStorage')
  },
  userRestore ({ commit }) {
    const item = sessionStorage.getItem('currentUser')
    if (item) {
      commit('saveCurrUser', JSON.parse(item))
      sessionStorage.removeItem('currentUser')
    }
  }
}

// mutations
const mutations = {
  saveCurrUser (state, user) {
    state.currentUser = user || { roles: [] }
    state.currentUser.avatar = state.currentUser.avatar || 'http://www.botwave.com/tmp/jshavatar/default-avatar.jpg'
  },
  userStorage (state) {
    sessionStorage.setItem('currentUser', JSON.stringify(state.currentUser))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
